import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  Select,
  Box,
  Chip,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { difference, isEqual, union } from "lodash";
import { useEditLenderMutation } from "../../../../store/slices/apiSlice";
// import { Add } from "@mui/icons-material";

// const ASSET_ENUMS = [
//   "ageOfAsset",
//   "ageOfAssetAtEnd",
//   "condition",
//   "assetType",
//   "purpose",
//   "assetValue",
//   "make",
//   "model",
//   "glassesMake",
//   "glassesModel",
//   "glassesVariant",
//   "glassesSeries",
//   "glassesNVIC",
//   "glassesDetails",
//   "specifications",
//   "registrationNumber",
//   "registrationState",
//   "vin",
//   "supplierName",
//   "supplierABN",
//   "supplierAddress",
//   "supplierPhone",
//   "supplierContactName",
//   "supplierEmail",
//   "privateSale",
//   "typeOfSale",
//   "description",
//   "application",
// ];

const FieldInput = ({ lender, editLender, requiredFieldList, setRequiredFieldList, keyString, field, title, fieldOptions, index }) => {
  const lenderId = lender?._id;
  const [initialValues, setInitialValues] = useState([]);

  const handleListChange = (event, { props }) => {
    // for list enums on select component
    const {
      target: { value },
    } = event;
    const newValue = typeof value === "string" ? value.split(",") : value;
    // let origValue = (lender?.requiredFields?.[keyString] ?? []).map((field) => field);
    let origValue = (requiredFieldList?.[keyString] ?? []).map((field) => field);
    let result;

    if (newValue.length > initialValues.length) {
      if (initialValues.length === 0) {
        result = [...origValue, ...newValue];
        if (!newValue?.includes(props?.value)) {
          result = origValue?.filter(val => val !== props?.value)
        }
        result = union(result)
        setInitialValues(newValue);
      } else {
        result = difference(newValue, initialValues);
        result = union(result, origValue);
        setInitialValues(newValue);
      }
    } else {
      result = difference(initialValues, newValue);
      result = difference(origValue, result);
      result = difference(origValue, [props?.value]);
      result = union(result)
      setInitialValues(newValue);
    }
    const requiredFields = {
      // ...lender.requiredFields,
      ...requiredFieldList,
      [keyString]: result,
    };
    setRequiredFieldList(requiredFields)

    // setLender({ 
    //   ...lender,
    //   // On autofill we get a stringified value.
    //   requiredFields,
    // });
  };

  const handleBlur = (e) => {
    // const newValue = typeof value === "string" ? value.split(",") : value;
    // // let origValue = (lender?.requiredFields?.[keyString] ?? []).map((field) => field);
    // let origValue = (requiredFieldList?.[keyString] ?? []).map((field) => field);
    // let result;
    // if (newValue.length > initialValues.length) {
    //   if (initialValues.length === 0) {
    //     result = [...origValue, ...newValue];
    //     setInitialValues(newValue);
    //   } else {
    //     result = difference(newValue, initialValues);
    //     result = union(result, origValue);
    //     setInitialValues(newValue);
    //   }
    // } else {
    //   result = difference(initialValues, newValue);
    //   result = difference(origValue, result);
    //   setInitialValues(newValue);
    // }
    const requiredFields = {
      // ...lender.requiredFields,
      ...requiredFieldList,
      [keyString]: requiredFieldList?.[keyString], // result
    };

    editLender({
      lenderId,
      body: {
        ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
        requiredFields,
      },
    });
  };

  const value = useMemo(() => {
    let valueArr = [];
    // (lender?.requiredFields[keyString] ?? []).forEach((f) => {
    (requiredFieldList?.[keyString] ?? []).forEach((f) => {
      (field?.value ?? []).forEach((val) => {
        let value;
        if (field.parent && field.isArray) {
          const getValue = () => {
            if (["phoneNumbers", "emails"].includes(field.fieldName)) return field.fieldName + "?.slice(-1)[0]." + val;
            else if (["employers"].includes(field.fieldName)) return field.fieldName + ".[0]." + val;
            else return field.fieldName + "[0]." + val;
          };
          value = getValue();
        } else if (field.parent && field.isArray) {
          value = field.fieldName + "." + val;
        } else {
          value = val;
        }
        if (f === value) valueArr.push(f);
      });
    });
    return valueArr;
    // }, [lender?.requiredFields[keyString], lender]);
  }, [requiredFieldList?.[keyString], lender]);

  const options = useMemo(() => {
    if (field?.parent && !field?.isArray) {
      return field?.value;
    } else if (field?.parent && field?.isArray) {
      const value = (field?.value ?? []).map((f) => {
        if (["phoneNumbers", "emails"].includes(field.fieldName)) return field.fieldName + "?.slice(-1)[0]." + f;
        else if (["employers"].includes(field.fieldName)) return field.fieldName + ".[0]." + f;
        return field.fieldName + `[0].` + f;
      });
      return value;
    } else {
      return field?.value;
    }
  }, [field]);

  useEffect(() => {
    setInitialValues(value);
    // setRequiredFieldList(lender?.requiredFields);
  }, []);

  if (title)
    return (
      <React.Fragment>
        <Grid container item xs={4}>
          <Typography fontWeight={"bold"}>{title?.charAt(0).toUpperCase() + title?.slice(1) + ":"}</Typography>
        </Grid>
        <Grid
          container
          item
          xs={8}
          {...(index !== fieldOptions?.length - 1 && field?.key && fieldOptions?.[index]?.key
            ? { mb: "10px" }
            : { mb: "0px" })}
        >
          <Select
            multiple
            fullWidth
            size="small"
            value={value ?? []}
            onChange={handleListChange}
            onBlur={handleBlur}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {(selected ?? []).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {(options ?? []).map((option, index) => (
              <MenuItem
                key={index}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {((field?.parent && fieldOptions?.[index + 1]?.key) || (field?.key && fieldOptions?.[index + 1]?.key)) && (
          <Grid item xs={12}>
            <Divider flexItem />
          </Grid>
        )}
      </React.Fragment>
    );
  return null;
};

// const FieldSection = React.memo(({ lender, fieldOptions }) => { // setLender
const FieldSection = React.memo(({ lender, setLender, fieldOptions }) => { // setLender
  // const [editLender, { data: editLenderData, isLoading: editLenderLoading }] = useEditLenderMutation();
  const [editLender] = useEditLenderMutation();
  const [requiredFieldList, setRequiredFieldList] = useState();

  // useEffect(() => {
  //   if (editLenderData && !editLenderLoading) debounce(() => setLender(editLenderData), 500);
  // }, [editLenderLoading]);

  const requiredFieldsList = useMemo(() => {
    let additionalFieldsList = [];
    let objToAdd = {};
    let requiredFieldsList = [];
    (fieldOptions ?? []).forEach((field) => {
      objToAdd = {
        key: field.key,
        value: [],
      };
      field.value.forEach((val) => {
        if (typeof val === "object" && val instanceof Object) {
          const isObjInArr = additionalFieldsList.some((f) => {
            const { parent, ...rest } = f;
            const { parent: p, ...r } = val;
            return isEqual(rest, r);
          });
          if (!isObjInArr)
            additionalFieldsList.push({
              parent: field.key,
              fieldName: val.key,
              isArray: val.isArray,
              value: val.value,
            });
        } else objToAdd.value.push(val);
      });
      requiredFieldsList.push(objToAdd);
    });
    additionalFieldsList.forEach((field) => {
      const index = requiredFieldsList.findIndex((f) => f.key === field.parent);
      requiredFieldsList.splice(index + 1, 0, field);
    });
    return requiredFieldsList;
  }, [fieldOptions]);

  useEffect(() => {
    setRequiredFieldList(lender?.requiredFields);
  }, []);

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Fields Needed</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
        direction={"column"}
        justifyContent={"center"}
        rowGap={"16px"}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          xs={9}
          direction="row"
          style={{ margin: "0px 0px 7px 0px" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>Required Fields List</AccordionSummary>
            <AccordionDetails sx={{ pt: "36px" }}>
              <Grid container xs={12} rowGap={"24px"}>
                {requiredFieldsList.map((field, index) => {
                  const keyString = field.key ?? field.parent;
                  return (
                    <FieldInput
                      key={index}
                      lender={lender}
                      requiredFieldList={requiredFieldList}
                      setRequiredFieldList={setRequiredFieldList}
                      editLender={editLender}
                      keyString={keyString}
                      field={field}
                      title={field.fieldName ?? field.key}
                      fieldOptions={requiredFieldsList}
                      index={index}
                    />
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default FieldSection;
