/* eslint-disable no-useless-escape */
const regex = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  emailOptional:
    /^((.{0})|(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  name: /^[A-Za-z\s\-]+$/,
  auPhoneNumber:
    /^\({0,1}((0|\+61)(2|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/,
  phoneNumber:
    /^\({0,1}((0|\+61)(2|3|4|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/,
  australianPhoneNumbers:
    /^(\+\d{2}[ \-]{0,1}){0,1}(((\({0,1}[ \-]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ \-]*(\d{4}[ \-]{0,1}\d{4}))|(1[ \-]{0,1}(300|800|900|902)[ \-]{0,1}((\d{6})|(\d{3}[ \-]{0,1}\d{3})))|(13[ \-]{0,1}([\d \-]{5})|((\({0,1}[ \-]{0,1})0{0,1}\){0,1}4{1}[\d \-]{8,10})))$/,
  allowOnlyNumber: /^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/,
  allowNumberWithZero: /^(?=.*[0-9])(?:[0-9]\d*\.?|0?\.)\d*$/,
  allowNumberAndDecimal: /^\d{1,7}(?:\.\d{1,2})?$/,
  allowOnlyOptionalNumber: /^((.{0})|((?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*))$/,
  alphaNumericAndSpecificChar: /^[a-zA-Z0-9.!/()\-\+\s]*$/,
  alphaNumericAndSpecificCharOptional: /^((.{0})|([a-zA-Z0-9.!/()\-\+\s]*))$/,
  stringRegex: /^[a-zA-Z]*$/,
  nameRegex: /^[a-zA-Z-'s]*$/,
  customerNameRegex: /^[a-zA-Z-'s ]*$/,
  occupationRegex: /^[a-zA-Z-',\(\)s ]*$/,
  nameOptionalRegex: /^((.{0})([a-zA-Z-'s ]*))$/,
  drivingPassportRegex: /^[A-Za-z0-9\-]*$/,
  drivingPassportRegexOptional: /^((.{0})|([A-Za-z0-9\- ]*))$/,
  dateOfBirthRegexOptional: /^((.{0})|((0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}))$/,
  customerDateOfBirthRegexOptional: /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/,
  allowStrictNumber: /^[0-9]+$/,
  addressRegex: /^[a-zA-Z0-9\s,\p{L},/'-]*$/,
  fanciableItemNameRegex: /^[A-Za-z\s,/-]*$/,
  ABNRegex: /^[a-zA-Z0-9\s\S][^<>ä]*$/,
  // percentageRegex: /^(?:100(?:\.0+)?|\d{1,2}(?:\.\d+)?|\.\d+)$/,
  percentageRegex: /^(?:100(?:\.0+)?|\d{1,2}(?:\.\d{1,2})?|\.\d+)$/,
  startCaseRegex: /\.(?=[^\.]+$)/,
};
export default regex;
