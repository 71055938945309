import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Delete } from "@mui/icons-material";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import AddCommonCriteriaInput from "./addCommonCriteria";
import { ERRORS_MESSAGE } from "../../../constants";
import { generateRandomId } from "../../Utils/helperFunction";
import { lenderSelector, updateCurrentlyEditingCommonCriteria } from "../../../store/slices/lenderSlice";
import { useBulkUpdateProductTiersMutation } from "../../../store/slices/apiSlice";
import CommonCriteriaInput from "../CommonCriteriaInput";
import criteria from "../../../utils/criteria";

const CommonCriteriaDialog = ({
  isDrawerOpen,
  setIsDrawerOpen,
  commonCriteria,
  setCommonCriteria,
  productsToEdit,
  setProductToEdit,
  // editProduct,
  setRowSelection,
  // setIsTableFetching
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { currentlyEditingCommonCriteria } = useSelector(lenderSelector);
  const [bulkEditProducts, { isLoading }] = useBulkUpdateProductTiersMutation();

  const [rangeToggle, setRangeToggle] = useState(false);
  const [incToggle, setIncToggle] = useState(false);
  const [rate, setRate] = useState(0);
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [commonCriteriaCopy, setCommonCriteriaCopy] = useState([]);
  const [newlyAddedCriteria, setNewlyAddedCriteria] = useState([]);

  // const [criteriaInputs, setCriteriaInputs] = useState([
  // Sample values
  // {
  //     "criteriaIndex": 3,
  //     "fieldName": "minValue",
  //     "value": 1
  // },
  // {
  //     "criteriaIndex": 3,
  //     "fieldName": "maxValue",
  //     "value": 2
  // },
  // {
  //     "criteriaIndex": 0,
  //     "fieldName": "value",
  //     "value": 1
  // },
  // {
  //     "criteriaIndex": 1,
  //     "fieldName": "selectedOptions",
  //     "value": [
  //         "Authorised seller",
  //     ]
  // },
  // {
  //     "criteriaIndex": 2,
  //     "fieldName": "value",
  //     "value": 1
  // }
  // ]);

  // Function to update input state
  // const updateCriteriaInput = (index, field, newValue) => {
  //   const criteriaInput = {
  //     criteriaIndex: index,
  //     fieldName: field,
  //     value: newValue,
  //   };

  //   setCriteriaInputs((prevInputs) => {
  //     const newCriteriaInputs = prevInputs.some((input) => input.criteriaIndex === index && input.fieldName === field)
  //       ? prevInputs.map((input) =>
  //           input.criteriaIndex === index && input.fieldName === field ? { ...input, value: newValue } : input,
  //         )
  //       : [...prevInputs, criteriaInput];

  //     return newCriteriaInputs;
  //   });
  // };

  // Check if any of the criteriaInputs have invalid values
  // const isSaveDisabled = () => {
  //   // If commonCriteria or criteriaInputs are empty, disable save
  //   if (commonCriteria.length === 0 || criteriaInputs.length === 0) {
  //     return true;
  //   }

  //   // Group the inputs by their criteriaIndex
  //   const groupedByIndex = criteriaInputs.reduce((acc, input) => {
  //     acc[input.criteriaIndex] = acc[input.criteriaIndex] || [];
  //     acc[input.criteriaIndex].push(input);
  //     return acc;
  //   }, {});

  //   // Iterate over the grouped inputs
  //   for (const inputs of Object.values(groupedByIndex)) {
  //     const minValueInput = inputs.find((input) => input.fieldName === "minValue");
  //     const maxValueInput = inputs.find((input) => input.fieldName === "maxValue");

  //     // If minValue exists but maxValue is missing, disable save
  //     if (minValueInput && !maxValueInput) {
  //       return true;
  //     }

  //     // If both minValue and maxValue exist, ensure they are both valid
  //     if (
  //       minValueInput &&
  //       maxValueInput &&
  //       (minValueInput.value === null ||
  //         minValueInput.value === "" ||
  //         minValueInput.value === undefined ||
  //         maxValueInput.value === null ||
  //         maxValueInput.value === "" ||
  //         maxValueInput.value === undefined)
  //     ) {
  //       return true;
  //     }

  //     // Validate other fields (like 'value' and 'selectedOptions')
  //     for (const input of inputs) {
  //       if (input.fieldName === "selectedOptions" && Array.isArray(input.value) && input.value.length === 0) {
  //         // Check if selectedOptions value is an empty array
  //         return true;
  //       } else if (
  //         input.fieldName === "value" &&
  //         (input.value === null || input.value === "" || input.value === undefined)
  //       ) {
  //         // Check if value field is null, blank string, or undefined
  //         return true;
  //       }
  //     }
  //   }

  //   // Ensure the number of valid inputs matches the number of commonCriteria
  //   if (Object.keys(groupedByIndex).length !== commonCriteria.length) {
  //     return true;
  //   }

  //   // Return false if none of the conditions are met (Save is enabled)
  //   return false;
  // };

  useEffect(() => {
    if (commonCriteriaCopy.length === 0 && commonCriteria.length > 0) {
      setCommonCriteriaCopy(commonCriteria);
      dispatch(updateCurrentlyEditingCommonCriteria(commonCriteria));
    }
  }, [commonCriteria]);

  const getCriteriaName = (criterion) =>
    Object.keys(criteria).find((key) => {
      if (typeof criteria[key] === "object" && criteria[key] instanceof Object) {
        const { object, attribute, condition } = criteria[key];
        return isEqual(
          { object, attribute, condition },
          {
            object: criterion.object,
            attribute: criterion.attribute,
            condition: criterion.condition,
          },
        );
      } else if (criteria[key].length === 1) {
        const { object, attribute, condition } = criteria[key](criterion.value);
        return isEqual(
          { object, attribute, condition },
          {
            object: criterion.object,
            attribute: criterion.attribute,
            condition: criterion.condition,
          },
        );
      } else {
        const min = criterion.valueMinMax ? criterion.valueMinMax.min : 0;
        const max = criterion.valueMinMax ? criterion.valueMinMax.max : 0;
        const { object, attribute, condition } = criteria[key](min, max);
        return isEqual(
          { object, attribute, condition },
          {
            object: criterion.object,
            attribute: criterion.attribute,
            condition: criterion.condition,
          },
        );
      }
    });

  const handleSave = () => {
    let productsToUpdate = [];
    productsToEdit.forEach((product) => {
      const { _id, createdAt, updatedAt, __v, criteria, ...rest } = product;
      const getRate = () => {
        if (!incToggle && !rangeToggle) return rate === 0 ? product.rate : rate;
        else if (!incToggle && rangeToggle)
          return [minRate === 0 ? product.rate[0] : minRate, maxRate === 0 ? product.rate[1] : maxRate];
        else if (Array.isArray(product.rate) && incToggle)
          return [Number((product.rate[0] + rate).toFixed(2)), Number((product.rate[1] + rate).toFixed(2))];
        return Number((Number(product.rate) + Number(rate)).toFixed(2));
      };
      const newCriteria = currentlyEditingCommonCriteria.map((cc) => {
        if (!cc.valueMinMax) return cc;
        else {
          const criteriaToCompare = criteria.find(
            (c) => c.attribute === cc.attribute && c.object === cc.object && c.condition === cc.condition,
          );
          return criteriaToCompare ?? {};
        }
      });

      const filteredNewCriteria = newCriteria
        .map((criterion) => {
          // Create a shallow copy of the criterion object
          const criterionCopy = { ...criterion };

          // Remove the 'new' key if it exists
          if ("new" in criterionCopy) {
            delete criterionCopy.new;
          }
          delete criterionCopy.id; // Remove temp id

          return criterionCopy;
        })
        .filter((criterion) => Object.keys(criterion).length > 0 && !criterion?.blank);

      const newProduct = {
        _id,
        ...rest,
        rate: getRate(),
        criteria: filteredNewCriteria,
      };

      productsToUpdate.push(newProduct);
      // editProduct({
      //   productTierId: _id,
      //   body: newProduct,
      // });
    });

    bulkEditProducts({ body: productsToUpdate })
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          setRangeToggle(false);
          setIncToggle(false);
          setRate(0);
          setMinRate(0);
          setMaxRate(0);
          setIsDirty(false);
          setCommonCriteria([]);
          setCommonCriteriaCopy([]);
          dispatch(updateCurrentlyEditingCommonCriteria([]));
          setProductToEdit([]);
          setRowSelection({});
          setIsDrawerOpen(false);
          // setIsTableFetching(true)
        }
      })
      .catch(() => {});
  };

  const handleRemoveCriteria = (criteria) => {
    // const newCommonCriteria = currentlyEditingCommonCriteria.filter(
    //   (c) => getCriteriaName(criteria) !== getCriteriaName(c),
    // );
    const newCommonCriteria = currentlyEditingCommonCriteria.filter((c) => criteria?.id !== c?.id);
    setCommonCriteria(newCommonCriteria);
    setCommonCriteriaCopy(newCommonCriteria);
    dispatch(updateCurrentlyEditingCommonCriteria(newCommonCriteria));
    setIsDirty(true);
  };

  const handleAddCriteria = () => {
    const criteria = {
      // id: commonCriteriaCopy.length + 1,
      id: generateRandomId(7),
      object: "",
      attribute: "",
      condition: "",
      value: 0,
      valueMinMax: {
        min: 0,
        max: 0,
      },
      new: true, // Temporary flag to distinguish between new and existing criteria
    };
    setCommonCriteria([...commonCriteria, criteria]);
    setCommonCriteriaCopy([...commonCriteriaCopy, criteria]);
    dispatch(updateCurrentlyEditingCommonCriteria([...commonCriteriaCopy, criteria]));
    setIsDirty(false);
  };

  return (
    <Dialog open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} fullWidth maxWidth="md">
      <Grid container p={"16px"} rowGap={"16px"}>
        <Grid item xs={12}>
          <Stack justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
            <Typography variant={"h6"}>Edit Products</Typography>
            <IconButton
              aria-label="close"
              disabled={isLoading}
              onClick={() => setIsDrawerOpen(false)}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
              disableRipple={true}
            >
              <Close />
            </IconButton>
          </Stack>
        </Grid>
        <Grid container item xs={12} columnGap={"16px"}>
          {!rangeToggle ? (
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Rate"
                variant="outlined"
                margin="dense"
                value={rate}
                onChange={(e) => {
                  setRate(e.target.value);
                  setIsDirty(true);
                }}
                onBlur={(e) => {
                  if (isNaN(rate)) setRate(0);
                  else setRate(parseFloat(rate));
                }}
              />
            </Grid>
          ) : (
            <Grid container item xs={4} columnSpacing={"16px"}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Min"
                  variant="outlined"
                  margin="dense"
                  value={minRate}
                  onChange={(e) => {
                    setMinRate(e.target.value);
                    setIsDirty(true);
                  }}
                  onBlur={(e) => {
                    if (isNaN(minRate)) setMinRate(0);
                    else setMinRate(parseFloat(minRate));
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Max"
                  variant="outlined"
                  margin="dense"
                  value={maxRate}
                  onChange={(e) => {
                    setMaxRate(e.target.value);
                    setIsDirty(true);
                  }}
                  onBlur={(e) => {
                    if (isNaN(maxRate)) setMaxRate(0);
                    else setMaxRate(parseFloat(maxRate));
                  }}
                />
              </Grid>
            </Grid>
          )}
          {!incToggle && (
            <FormControlLabel
              control={<Switch checked={rangeToggle} onChange={() => setRangeToggle(!rangeToggle)} />}
              label="Switch to Range"
            />
          )}
          <FormControlLabel
            control={
              <Switch
                checked={incToggle}
                onChange={() => {
                  setIncToggle(!incToggle);
                  setRangeToggle(false);
                }}
              />
            }
            label="Switch to Increment/Decrement"
          />
        </Grid>
        <Grid container xs={12} direction={"row"} alignItems={"center"}>
          <Typography mb={"8px"} mr={"16px"}>
            Criteria
          </Typography>
          <IconButton sx={{ p: 0, mt: "-5px" }} onClick={handleAddCriteria}>
            <Add />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{ overflowY: commonCriteria.length > 4 ? "scroll" : "initial", mb: 7.5 }}
          maxHeight={"300px"}
        >
          <Grid container item xs={12} rowSpacing={"16px"}>
            {(commonCriteria ?? []).map((criterion, index) => (
              <Grid container item xs={12} alignItems={"center"} key={criterion.id}>
                <Grid item xs={4}>
                  <AddCommonCriteriaInput
                    // key={index}
                    getCriteriaName={getCriteriaName}
                    commonCriteria={commonCriteriaCopy}
                    keyId={criterion.id}
                    criteriaIndex={index}
                    criterion={criterion}
                    setCommonCriteria={setCommonCriteriaCopy}
                    setIsDirty={setIsDirty}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <CommonCriteriaInput
                    originalCommonCriteria={commonCriteria}
                    // commonCriteria={commonCriteriaCopy}
                    setCommonCriteria={setCommonCriteriaCopy}
                    setOriginalCommonCriteria={setCommonCriteria}
                    keyId={criterion.id}
                    criteriaIndex={index}
                    getCriteriaName={getCriteriaName}
                    productsToEdit={productsToEdit}
                    setProductToEdit={setProductToEdit}
                    setIsDirty={setIsDirty}
                    newlyAddedCriteria={newlyAddedCriteria}
                    setNewlyAddedCriteria={setNewlyAddedCriteria}
                    // updateCriteriaInput={updateCriteriaInput}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton disableRipple onClick={() => handleRemoveCriteria(criterion)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Button
          variant="contained"
          disabled={commonCriteria.length === 0 || !isDirty || isLoading}
          onClick={handleSave}
        >
          {isLoading ? "Updating..." : "SAVE CHANGES"}
        </Button>
      </Grid>
    </Dialog>
  );
};

export default CommonCriteriaDialog;
