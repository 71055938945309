import CRITERIA from "./utils/criteria";

export const typeOfSaleList = {
  DEALER_SALE: "Authorised seller",
  EV_SALE: "Authorised electric vehicle seller",
  PRIVATE_SALE: "Private sale",
  MID_TERM_REFINANCE: "Mid-term refinance",
  BALLOON_RESIDUAL_REFINANCE: "Balloon / Residual Refinance",
  SALE_BUYBACK_EQUITY: "Sale & buyback / Equity",
};

export const REACT_APP_UNAUTHORIZED_REDIRECT = process.env.REACT_APP_UNAUTHORIZED_REDIRECT;

export const assetTypes = {
  MOTOR_VEHICLE_UNDER_4_5_TONNES: "Motor Vehicle (<4.5 tonnes)",
  COMMERCIAL_SMALL_MEDIUM_LESS_THAN_12_TONNES: "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
  COMMERCIAL_HEAVY_VEHICLE_GREATER_THAN_12_TONNES: "Commercial (Heavy) Vehicles (>12 Tonnes)",
};

// Criteria functions that have dropdown options
export const FUNCTIONS_WITH_ENUMS = [
  "employmentType",
  "assetType",
  "typeOfSale",
  "livingArrangements",
  "assetCondition",
];

export const FUNCTIONS_DATE_VALUES = ["gst", "timeInBusiness", "ageOfAsset", "dateOfBirth"]; // function prefixes that returns computed date values

export const FUNCTIONS_DATE_USING_MONTHS = ["gst", "timeInBusiness", "ageOfAsset"];

export const FUNCTIONS_DATE_USING_YEARS = ["dateOfBirth"];

export const AGE_OF_ASSETS_AT_END = ["ageOfAssetAtEndGreaterThan", "ageOfAssetAtEndLessThan", "ageOfAssetAtEndBetween"];

export const FUNCTIONS_PARSE_TO_STRING = [
  "ageOfAssetAtEnd",
  "creditScore",
  "deposit",
  "termIs",
  "timeOfEmployment",
  ...FUNCTIONS_DATE_VALUES,
]; // function prefixes that returns either a number (weeks, years) or date

export const FEE_FREQUENCIES = ["upfront", "monthly"];

export const criteriaObjects = Object.keys(CRITERIA).filter(
  (key) => CRITERIA[key] instanceof Object && typeof CRITERIA[key] === "object",
);

export const criteriaFunctions = Object.keys(CRITERIA).filter(
  (key) => !(CRITERIA[key] instanceof Object && typeof CRITERIA[key] === "object"),
);

export const CREDENTIAL_ATTRIBUTES = ["email", "username", "password", "organisationId", "brokerId", "apiKey", "pin"];

export const LENDER_ACTIONS = ["upload", "download", "email"];

export const LOADING_VALUE_TYPES = ["fixed", "percentage"];

export const productTypes = ["Standard", "Low doc", "Light doc", "Full doc", "Adverse"];

export const ADDRESS_STATUS_TYPE = {
  CURRENT: "current",
  PREVIOUS: "previous",
};

export const AddressStatusList = ["current", "previous"];

export const stateAbbreviationList = ["ACT", "NSW", "NT", "QLD", "TAS", "VIC", "WA"];

export const stateList = [
  "Australian Capital Territory",
  "New South Wales",
  "Northern Territory",
  "Queensland",
  "Tasmania",
  "Victoria",
  "Western Australia",
  "South Australia",
];

export const getAbbreviationState = {
  "Australian Capital Territory": "ACT",
  "New South Wales": "NSW",
  "Northern Territory": "NT",
  Queensland: "QLD",
  Tasmania: "TAS",
  Victoria: "VIC",
  "Western Australia": "WA",
  "South Australia": "SA",
};

export const streetTypeList = [
  "Access",
  "Alley",
  "Alleyway",
  "Amble",
  "Anchorage",
  "Approach",
  "Arcade",
  "Artery",
  "Avenue",
  "Bank",
  "Basin",
  "Beach",
  "Bend",
  "Block",
  "Boulevard",
  "Brace",
  "Brae",
  "Break",
  "Bridge",
  "Broadway",
  "Brow",
  "Bypass",
  "Byway",
  "Causeway",
  "Centre",
  "Centreway",
  "Chase",
  "Circle",
  "Circlet",
  "Circuit",
  "Circus",
  "Close",
  "Colonnade",
  "Common",
  "Concourse",
  "Copse",
  "Corner",
  "Corso",
  "Court",
  "Courtyard",
  "Cove",
  "Crescent",
  "Crossing",
  "Crossroad",
  "Crossway",
  "Cruiseway",
  "Cul-de-sac",
  "Cutting",
  "Dale",
  "Dell",
  "Deviation",
  "Dip",
  "Distributor",
  "Drive",
  "Driveway",
  "Edge",
  "Elbow",
  "End",
  "Entrance",
  "Esplanade",
  "Estate",
  "Expressway",
  "Extension",
  "Fairway",
  "FireTrail",
  "Flat",
  "Follow",
  "Footway",
  "Foreshore",
  "Formation",
  "Freeway",
  "Front",
  "Frontage",
  "Gap",
  "Garden",
  "Gardens",
  "Gate",
  "Gates",
  "Glade",
  "Glen",
  "Grange",
  "Green",
  "Ground",
  "Grove",
  "Gully",
  "Heights",
  "Highroad",
  "Highway",
  "Hill",
  "Interchange",
  "Intersection",
  "Junction",
  "Key",
  "Landing",
  "Lane",
  "Laneway",
  "Lees",
  "Line",
  "Link",
  "Little",
  "Lookout",
  "Loop",
  "Lower",
  "Mall",
  "Meander",
  "Mew",
  "Mews",
  "Motorway",
  "Mount",
  "Nook",
  "Outlook",
  "Parade",
  "Park",
  "Parklands",
  "Parkway",
  "Part",
  "Pass",
  "Path",
  "Piazza",
  "Place",
  "Plateau",
  "Plaza",
  "Pocket",
  "Point",
  "Port",
  "Promenade",
  "Quad",
  "Quadrangle",
  "Quadrant",
  "Quay",
  "Quays",
  "Ramble",
  "Ramp",
  "Range",
  "Reach",
  "Reserve",
  "Rest",
  "Retreat",
  "Ride",
  "Ridge",
  "Ridgeway",
  "Ring",
  "Rise",
  "River",
  "Riverway",
  "Riviera",
  "Road",
  "Roads",
  "Roadside",
  "Roadway",
  "Ronde",
  "Rosebowl",
  "Rotary",
  "Round",
  "Route",
  "Row",
  "Rue",
  "Run",
  "Siding",
  "Slope",
  "Sound",
  "Spur",
  "Square",
  "Stairs",
  "StateHighway",
  "Steps",
  "Street",
  "Strip",
  "Terrace",
  "Thoroughfare",
  "Tollway",
  "Top",
  "Tor",
  "Towers",
  "Track",
  "Trail",
  "Trailer",
  "Triangle",
  "Trunkway",
  "TURN",
  "Underpass",
  "Upper",
  "Vale",
  "Viaduct",
  "View",
  "Vista",
  "Wade",
  "Walk",
  "Walkway",
  "Way",
  "Wharf",
  "Wynd",
  "Yard",
];

export const livingArrangementsTypes = {
  PROPERTY_OWNER: "Property owner",
  RENTING: "Renting",
  BOARDING: "Boarding",
  LIVING_WITH_PARENTS: "Living w/ parents",
};

export const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

export const criteriaTypes = ["No deposit", "No property"];

export const productLenderFilterKey = "productLenderFilters";

export const commercialLenderList = [
  "AFS",
  "AMMF",
  "Angle Finance",
  "ANZ",
  "Azora Asset Finance",
  "Branded",
  "Capital Finance",
  "Dynamoney",
  "Finance One",
  "Flexi",
  "Group & General Finance",
  "GRENKE",
  "Liberty",
  "Macquarie",
  "Metro",
  "Pepper",
  "Plenti",
  "resimac",
  "ScotPac",
  "Selfco",
  "Shift",
  "Westpac",
  "Volkswagen Financial Services",
];

export const consumerLenderList = [
  "Affordable Car Loans",
  "Alex Bank",
  "AMMF",
  "Australian Premier Finance",
  "Autopay",
  "Branded",
  "Car Start",
  "Finance One",
  "FirstMac",
  // "Gedda Money",
  "Greenlight",
  "Latitude",
  "Liberty",
  "Macquarie",
  "Money3",
  "MoneyPlace",
  "NOW",
  "Pepper",
  "Plenti",
  "Salt & Lime",
  "WISR",
];

export const personalLoanLenderList = [
  "Alex Bank",
  "Latitude",
  "Money3",
  // "MoneyPlace",
  "NOW",
  "Plenti",
  "WISR",
];

export const LOAN_PURPOSE_TYPES = [
  "Vehicle",
  "Debt Consolidation",
  "Home improvements",
  "Medical & Dental",
  "Household furnishings",
  "Education",
  "Travel",
  "Wedding",
  "Mortgage Cost Funding",
  "Other Personal Use",
];

export const APPLICATION_TYPES = {
  CONSUMER: "consumer",
  COMMERCIAL: "commercial",
  PERSONAL: "personal",
};

export const consumerFinanceCriteriaFilterList = [
  "ageOfAsset",
  "ageOfAssetAtEnd",
  "assetType",
  "dateOfBirth",
  "deposit",
  "employmentType",
  "livingArrangements",
  "loanAmount",
  "score",
  "timeOfEmployment",
  "typeOfSale",
];

export const commercialFinanceCriteriaFilterList = [
  "ageOfAsset",
  "ageOfAssetAtEnd",
  "assetType",
  "deposit",
  "gst",
  "livingArrangements",
  "loanAmount",
  "score",
  "timeInBusiness",
  "typeOfSale",
];

export const defaultCreditScoreValues = {
  [APPLICATION_TYPES.CONSUMER]: ["ONE_1.0_X_CR", "VSA_2.0_X_CR", "VS_1.1_X_NR"],
  [APPLICATION_TYPES.PERSONAL]: ["ONE_1.0_X_CR", "VSA_2.0_X_CR", "VS_1.1_X_NR"],
  [APPLICATION_TYPES.COMMERCIAL]: ["CCAI_2.0_YX_CR", "CCAI_2.0_YX_NR", "VS_1.1_YX_NR"],
};

export const personalFinanceCriteriaFilterList = [
  "ageOfAsset",
  "ageOfAssetAtEnd",
  "assetType",
  "dateOfBirth",
  // "deposit",
  "employmentType",
  "livingArrangements",
  "loanAmount",
  "score",
  "timeOfEmployment",
  "typeOfSale",
];

export const PLATFORM_NAME = {
  CONNECTIVE: "connective",
  BMA: "bma",
  AFS: "afs",
};

export const CreditScoreTypes = {
  [APPLICATION_TYPES.CONSUMER]: [
    {
      label: "Equifax One Score 1.0 Comprehensive Consumer",
      value: "ONE_1.0_X_CR",
      permissionType: "X",
      productDataLevel: "C",
    },
    {
      label: "Equifax Apply 2.0 Comprehensive Consumer",
      value: "VSA_2.0_X_CR",
      permissionType: "X",
      productDataLevel: "C",
    },
    {
      label: "Equifax Apply 2.0 Negative Consumer",
      value: "VSA_2.0_X_NR",
      permissionType: "X",
      productDataLevel: "N",
    },
    {
      label: "VedaScore 1.1 Negative Consumer",
      value: "VS_1.1_X_NR",
      permissionType: "X",
      productDataLevel: "N",
    },
  ],
  [APPLICATION_TYPES.PERSONAL]: [
    {
      label: "Equifax One Score 1.0 Comprehensive Consumer",
      value: "ONE_1.0_X_CR",
      permissionType: "X",
      productDataLevel: "C",
    },
    {
      label: "Equifax Apply 2.0 Comprehensive Consumer",
      value: "VSA_2.0_X_CR",
      permissionType: "X",
      productDataLevel: "C",
    },
    {
      label: "Equifax Apply 2.0 Negative Consumer",
      value: "VSA_2.0_X_NR",
      permissionType: "X",
      productDataLevel: "N",
    },
    {
      label: "VedaScore 1.1 Negative Consumer",
      value: "VS_1.1_X_NR",
      permissionType: "X",
      productDataLevel: "N",
    },
  ],
  [APPLICATION_TYPES.COMMERCIAL]: [
    {
      label: "Equifax One Score 1.0 Comprehensive Consumer + Commercial",
      value: "ONE_1.0_XY_CR", // 'ONE_1.0_XY_CR_B2C'
      permissionType: "XY",
      productDataLevel: "C",
    },
    {
      label: "Equifax Apply 2.0 Comprehensive Consumer + Commercial",
      value: "VSA_2.0_XY_CR",
      permissionType: "XY",
      productDataLevel: "C",
    },
    {
      label: "Equifax Apply 2.0 Negative Consumer + Commercial",
      value: "VSA_2.0_XY_NR",
      permissionType: "XY",
      productDataLevel: "N",
    },
    {
      label: "Equifax Commercial Apply 2.0 Comprehensive Commercial + Consumer",
      value: "CCAI_2.0_YX_CR",
      permissionType: "YX",
      productDataLevel: "C",
    },
    {
      label: "Equifax Commercial Apply 2.0 Negative Commercial + Consumer",
      value: "CCAI_2.0_YX_NR",
      permissionType: "YX",
      productDataLevel: "N",
    },
    // {
    //   label: 'Equifax Commercial Apply 2.0 Negative Commercial',
    //   value: 'CCAI_2.0_Y_NR',
    //   permissionType: 'Y',
    //   productDataLevel: 'N',
    // },
    {
      label: "VedaScore 1.1 Negative Consumer + Commercial",
      value: "VS_1.1_XY_NR",
      permissionType: "XY",
      productDataLevel: "N",
    },
    {
      label: "VedaScore 1.1 Negative Commercial + Consumer",
      value: "VS_1.1_YX_NR",
      permissionType: "YX",
      productDataLevel: "N",
    },
    // {
    //   label: 'VedaScore 1.1 Negative Commercial',
    //   value: 'VS_1.1_Y_NR',
    //   permissionType: 'Y',
    //   productDataLevel: 'N',
    // },
    {
      label: "Equifax One Score 1.0 Comprehensive Consumer",
      value: "ONE_1.0_X_CR",
      permissionType: "X",
      productDataLevel: "C",
    },
    {
      label: "Equifax Apply 2.0 Comprehensive Consumer",
      value: "VSA_2.0_X_CR",
      permissionType: "X",
      productDataLevel: "C",
    },
    {
      label: "Equifax Apply 2.0 Negative Consumer",
      value: "VSA_2.0_X_NR",
      permissionType: "X",
      productDataLevel: "N",
    },
    {
      label: "VedaScore 1.1 Negative Consumer",
      value: "VS_1.1_X_NR",
      permissionType: "X",
      productDataLevel: "N",
    },
  ],
};

export const getCriteriaFilterList = {
  [APPLICATION_TYPES.CONSUMER]: consumerFinanceCriteriaFilterList,
  [APPLICATION_TYPES.COMMERCIAL]: commercialFinanceCriteriaFilterList,
  [APPLICATION_TYPES.PERSONAL]: personalFinanceCriteriaFilterList,
};

export const sortList = [
  ["fit", "desc"],
  ["repayments", "asc"],
  ["rate", "asc"],
  ["loanAmount", "asc"],
  ["fees", "asc"],
];

export const employerWorkStatus = [
  {
    label: "Current",
    value: "current",
  },
  {
    label: "Previous",
    value: "previous",
  },
];

export const employmentTypes = {
  FULL_TIME: "Full-time",
  PART_TIME: "Part-time",
  CASUAL: "Casual",
  CONTRACT: "Contract",
  SELF_EMPLOYED: "Self employed",
  BENEFITS: "Benefits",
};

export const userType = {
  user: "user",
  owner: "owner",
  admin: "admin",
  support: "support",
  master: "master",
};

export const makeType = {
  Abarth: "Abarth",
  Alpine: "Alpine",
  AstonMartin: "Aston Martin",
  Audi: "Audi",
  Bentley: "Bentley",
  BMW: "BMW",
  BMW_ALPINA: "BMW ALPINA",
  Caterham: "Caterham",
  Chevrolet: "Chevrolet",
  Chrysler: "Chrysler",
  Citroen: "Citroen",
  Ferrari: "Ferrari",
  Fiat: "Fiat",
  Ford: "Ford",
  Genesis: "Genesis",
  GWM: "GWM",
  Haval: "Haval",
  Honda: "Honda",
  Hyundai: "Hyundai",
  Isuzu: "Isuzu",
  Iveco: "Iveco",
  Jaguar: "Jaguar",
  Jeep: "Jeep",
  Kia: "Kia",
  Lamborghini: "Lamborghini",
  LandRover: "Land Rover",
  LDV: "LDV",
  Lexus: "Lexus",
  Lotus: "Lotus",
  Mahindra: "Mahindra",
  Maserati: "Maserati",
  Mazda: "Mazda",
  McLaren: "McLaren",
  MercedesBenz: "Mercedes-Benz",
  Maybach: "Maybach",
  MG: "MG",
  MINI: "MINI",
  Mitsubishi: "Mitsubishi",
  Morgan: "Morgan",
  Nissan: "Nissan",
  Peugeot: "Peugeot",
  Porsche: "Porsche",
  Ram: "Ram",
  Renault: "Renault",
  RollsRoyce: "Rolls-Royce",
  Skoda: "Skoda",
  Ssangyong: "Ssangyong",
  Subaru: "Subaru",
  Suzuki: "Suzuki",
  Tesla: "Tesla",
  Toyota: "Toyota",
  Volkswagen: "Volkswagen",
  Volvo: "Volvo",
};

export const assetTypesList = [
  {
    label: "Residential property (occupying)",
    value: "Residential property (occupying)",
  },
  {
    label: "Invesment property",
    value: "Invesment property",
  },
  {
    label: "Commercial property",
    value: "Commercial property",
  },
  {
    label: "Motor vehicle",
    value: "Motor vehicle",
  },
  {
    label: "Cash (savings)",
    value: "Cash (savings)",
  },
  {
    label: "Invesments (shares)",
    value: "Invesments (shares)",
  },
  {
    label: "Superannuation",
    value: "Superannuation",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const liabilitiesTypesList = [
  {
    label: "Mortgage",
    value: "Mortgage",
  },
  {
    label: "Motor vehicle",
    value: "Motor vehicle",
  },
  {
    label: "Credit card",
    value: "Credit card",
  },
  {
    label: "Personal loan",
    value: "Personal loan",
  },
  {
    label: "Afterpay, Zip, etc…",
    value: "Afterpay, Zip, etc…",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const incomeTypesList = [
  {
    label: "Primary",
    value: "Primary",
  },
  {
    label: "Secondary",
    value: "Secondary",
  },
  {
    label: "Invesment property",
    value: "Invesment property",
  },
  {
    label: "Dividends",
    value: "Dividends",
    label: "Dividends",
    value: "Dividends",
  },
  {
    label: "Superannuation",
    value: "Superannuation",
  },
  {
    label: "Pension",
    value: "Pension",
  },
  {
    label: "Trust",
    value: "Trust",
  },
  {
    label: "Benefits",
    value: "Benefits",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const expensesTypeList = [
  {
    label: "Rent",
    value: "Rent",
  },
  {
    label: "Utilities",
    value: "Utilities",
  },
  {
    label: "Groceries",
    value: "Groceries",
  },
  {
    label: "Dining Out",
    value: "Dining Out",
  },
  {
    label: "Insurance",
    value: "Insurance",
  },
  {
    label: "Telecommunications",
    value: "Telecommunications",
  },
  {
    label: "Subscription TV",
    value: "Subscription TV",
  },
  {
    label: "Education and Childcare",
    value: "Education and Childcare",
  },
  {
    label: "Vehicles and Transport",
    value: "Vehicles and Transport",
  },
  {
    label: "Personal Care",
    value: "Personal Care",
  },
  {
    label: "Health",
    value: "Health",
  },
  {
    label: "Department Stores",
    value: "Department Stores",
  },
  {
    label: "Retail",
    value: "Retail",
  },
  {
    label: "Home Improvement",
    value: "Home Improvement",
  },
  {
    label: "Entertainment",
    value: "Entertainment",
  },
  {
    label: "Gym and other memberships",
    value: "Gym and other memberships",
  },
  {
    label: "Travel",
    value: "Travel",
  },
  {
    label: "Pet Care",
    value: "Pet Care",
  },
  {
    label: "Uncategorized Debts",
    value: "Uncategorized Debts",
  },
];

export const frequencyList = [
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Fortnightly",
    value: "Fortnightly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
];

export const frequencyForIncomeList = [
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Fortnightly",
    value: "Fortnightly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Annually",
    value: "Annually",
  },
];

export const industryList = [
  {
    label: "ACCOMMODATION AND FOOD SERVICES",
    value: "ACCOMMODATION AND FOOD SERVICES",
  },
  {
    label: "ADMINISTRATIVE AND SUPPORT SERVICES",
    value: "ADMINISTRATIVE AND SUPPORT SERVICES",
  },
  {
    label: "AGRICULTURE, FORESTRY AND FISHING",
    value: "AGRICULTURE, FORESTRY AND FISHING",
  },
  {
    label: "ARTS AND RECREATION SERVICES",
    value: "ARTS AND RECREATION SERVICES",
  },
  {
    label: "CONSTRUCTION",
    value: "CONSTRUCTION",
  },
  {
    label: "EDUCATION AND TRAINING",
    value: "EDUCATION AND TRAINING",
  },
  {
    label: "ELECTRICITY, GAS, WATER AND WASTE SERVICES",
    value: "ELECTRICITY, GAS, WATER AND WASTE SERVICES",
  },
  {
    label: "FINANCIAL AND INSURANCE SERVICES",
    value: "FINANCIAL AND INSURANCE SERVICES",
  },
  {
    label: "HEALTH CARE AND SOCIAL ASSISTANCE",
    value: "HEALTH CARE AND SOCIAL ASSISTANCE",
  },
  {
    label: "INFORMATION MEDIA AND TELECOMMUNICATIONS",
    value: "INFORMATION MEDIA AND TELECOMMUNICATIONS",
  },
  {
    label: "MANUFACTURING",
    value: "MANUFACTURING",
  },
  {
    label: "MINING",
    value: "MINING",
  },
  {
    label: "OTHER SERVICES",
    value: "OTHER SERVICES",
  },
  {
    label: "PROFESSIONAL, SCIENTIFIC AND TECHNICAL SERVICES",
    value: "PROFESSIONAL, SCIENTIFIC AND TECHNICAL SERVICES",
  },
  {
    label: "PUBLIC ADMINISTRATION AND SAFETY",
    value: "PUBLIC ADMINISTRATION AND SAFETY",
  },
  {
    label: "RENTAL HIRING AND REAL ESTATE SERVICES",
    value: "RENTAL HIRING AND REAL ESTATE SERVICES",
  },
  {
    label: "RETAIL TRADE",
    value: "RETAIL TRADE",
  },
  {
    label: "TRANSPORT, POSTAL AND WAREHOUSING",
    value: "TRANSPORT, POSTAL AND WAREHOUSING",
  },
  {
    label: "WHOLESALE TRADE",
    value: "WHOLESALE TRADE",
  },
];

export const validIndustryList = industryList.map((item) => item.value);

export const customerTitleList = [
  {
    label: "Mr",
    value: "Mr",
  },
  {
    label: "Mrs",
    value: "Mrs",
  },
  {
    label: "Ms",
    value: "Ms",
  },
  {
    label: "Miss",
    value: "Miss",
  },
];

export const validCustomerTitle = ["Mr", "Mrs", "Ms", "Miss"];

export const validCustomerAssetType = [
  "Residential property (occupying)",
  "Invesment property",
  "Commercial property",
  "Motor vehicle",
  "Cash (savings)",
  "Invesments (shares)",
  "Superannuation",
  "Other",
];

export const validLiabilityType = [
  "Mortgage",
  "Motor vehicle",
  "Credit card",
  "Personal loan",
  "Afterpay, Zip, etc…",
  "Other",
];

// export const validIncomeTypesList = [
//   "Primary",
//   "Secondary",
//   "Invesment property",
//   "Dividends",
//   "Superannuation",
//   "Pension",
//   "Trust",
//   "Benefits",
//   "Other",
// ];

// export const validExpenseTypesList = [
//   "Rent",
//   "Utilities",
//   "Groceries",
//   "Dining Out",
//   "Insurance",
//   "Telecommunications",
//   "Subscription TV",
//   "Education and Childcare",
//   "Vehicles and Transport",
//   "Personal Care",
//   "Health",
//   "Department Stores",
//   "Retail",
//   "Home Improvement",
//   "Entertainment",
//   "Gym and other memberships",
//   "Travel",
//   "Pet Care",
//   "Uncategorized Debts",
// ];

export const calculationMethods = ["Chattel Mortgage", "Consumer finance", "Lease"];

export const StateList = [
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
  // "OTHER"
];

export const validFrequencyList = ["Weekly", "Fortnightly", "Monthly", "Annually"];

export const RepaymentStructure = [
  // "Custom Payments",
  // "Group Payments",
  "Single Payments",
];

export const ConditionFieldOptions = ["New", "Demo", "Used"];

export const userTypeOptions = ["master", "owner", "admin", "user", "support"];

export const userTypes = {
  owner: "owner",
};

export const getApplicationTab = {
  quote: "quote",
  application: "application",
  calculator: "calculator",
};

const applicationTypeList = [
  {
    label: "Consumer Asset",
    value: APPLICATION_TYPES.CONSUMER,
    key: "startConsumerAssetApp",
  },
  {
    label: "Commercial Asset",
    value: APPLICATION_TYPES.COMMERCIAL,
    key: "startCommercialAssetApp",
  },
  {
    label: "Unsecured Consumer Loan",
    value: APPLICATION_TYPES.PERSONAL,
    key: "startPersonalAssetApp",
  },
];

const quoteApplicationTypeList = [
  {
    label: "Consumer Asset",
    value: APPLICATION_TYPES.CONSUMER,
    key: "startConsumerAssetApp",
  },
  {
    label: "Commercial Asset",
    value: APPLICATION_TYPES.COMMERCIAL,
    key: "startCommercialAssetApp",
  },
];

const calculatorApplicationTypeList = [
  {
    label: "Consumer Asset",
    value: APPLICATION_TYPES.CONSUMER,
    key: "startConsumerAssetApp",
  },
  {
    label: "Commercial Asset",
    value: APPLICATION_TYPES.COMMERCIAL,
    key: "startCommercialAssetApp",
  },
];

export const getApplicationTypeList = {
  quote: quoteApplicationTypeList,
  application: applicationTypeList,
  calculator: calculatorApplicationTypeList,
};

export const getStartApplicationUrl = {
  quote: `application/start/quote`,
  application: `application/start`,
  calculator: `application/start/calculator`,
};

export const defaultRevenueFeesList = [
  {
    name: "financedFee",
    label: "Financed fee",
    key: "fee",
    value: 0,
  },
  {
    name: "financedBrokerage",
    label: "Financed fee",
    key: "brokerage",
    value: 0,
  },
  {
    name: "unFinancedFee",
    label: "Unfinanced fee",
    key: "fee",
    value: 0,
  },
  {
    name: "unFinancedBrokerage",
    label: "Unfinanced fee",
    key: "brokerage",
    value: 0,
  },
  {
    name: "accountFee",
    label: "Account fee (Net)",
    key: "fee",
    value: 0,
  },
];

export const typesOfFeesWithKey = [
  {
    label: "Origination Fee",
    value: "originationFee",
  },
  {
    label: "Inspection Fee",
    value: "inspectionFee",
  },
  // {
  //   label: "Application Fee",
  //   value: "applicationFee",
  // },
  {
    label: "Registration Fee",
    value: "registrationFee",
  },
  {
    label: "Establishment Fee",
    value: "establishmentFee",
  },
  {
    label: "Document Fee",
    value: "documentFee",
  },
  {
    label: "Account Fee",
    value: "accountFee",
  },
  {
    label: "Referral Fee",
    value: "referralFee",
  },
  {
    label: "Monthly Fee",
    value: "monthlyFee",
  },
];

export const typesOfFees = [
  "originationFee",
  "inspectionFee",
  // 'applicationFee',
  "registrationFee",
  "establishmentFee",
  "documentFee",
  "accountFee",
  "referralFee",
  "monthlyFee",
];

export const SUCCESS_MESSAGE = {
  linkMsg: "Link sent",
  generatePdf: "The pdf has been successfully generated",
  asic: "ASIC data retrieved",
  fetchMsg: "Data fetch Successfully",
  creditFile: "Credit file retrieved",
  bankStatements: "Bank statements link sent",
  privacy: "Privacy statement link sent",
  disclosureLink: "Disclosure link sent",
  idVerification: "Identity verification link sent",
  successLinkMsg: "Request has been sent. Waiting for customer response",
  fileUploadSuccess: "File uploaded successfully",
  applicationSubmitted: "Application submitted Successfully",
  organisationCreate: "Organisation created successfully.",
};

export const ALLOWED_DOCUMENT_TYPE = [
  // "application/msword",
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "image/jpeg",
  "image/jpg",
  // "image/gif",
  "image/png",
];

export const VALID_DOCUMENT_TYPE_EXTENSION = ".pdf, .jpg, .jpeg, .png"; // .doc, .docx, .gif

export const MAX_DOCUMENT_UPLOAD_SIZE = 3 * 1024 * 1024; // 3 MB in bytes

export const ERRORS_MESSAGE = {
  fetchErrorMsg: "Something went wrong",
};

export const FILE_AWAITING_MESSAGE = {
  gettingEquifaxCreditPDFMsg: "Waiting for Equifax to send the PDF file",
};

export const errorField = [
  // "addresses[0].streetAddress",
  "addresses[0].city",
  "addresses[0].state",
  "addresses[0].postCode",
  "emails?.slice(-1)[0].address",
  "phoneNumbers?.slice(-1)[0].number",
];

// export const errorForAddress = [
//   "addresses[0].yearsAt",
//   "addresses[0].monthsAt",
//   "addresses[0].city",
//   "addresses[0].state",
//   "addresses[0].postCode",
// ];

export const agreementDetail = [
  `you have the legal authority to submit this personal loan
application on behalf of the borrower(s); and`,
  `the information provided to the lender in this application is
  accurate; and`,
  `you have disclosed any information known to you which may be
  relevant to lender’s decision in relation to the application; and`,
  `you must not engage in any misleading or deceptive conduct, or
  made any representation to the applicant(s) as to the likelihood of
  the success of their application; and`,
  `If any insurance or warranties (such as gap insurance or extended
    warranty) is to be financed under this loan, you the Introducer,
    confirm and warrant that:`,
];

export const pointDetail = [
  `If you are a mortgage broker, you represent and warrant that you
have complied with your obligation to act in the customer’s best
interests;`,
  `you have made reasonable inquiries of the applicant(s) about their
requirements and objectives in respect of each insurance or
warranty;`,
  `you have assessed that each insurance or warranty proposed to be
financed under this loan meets the applicant(s) requirements and
objectives, and (if applicable) that the warranty provides a genuine
benefit over and above any statutory warranty;`,
  `you have explained the excess fee, the coverage, and the limitations
of the relevant insurance or warranty, and the cost of the insurance
premium or warranty fee to the applicant(s) (and compared the cost of
the insurance premium or warranty fee to the value of the motor
vehicle). The applicant(s) was/were satisfied with the features,
limitations and cost; and`,
  `you have not engaged in pressure selling, or any conduct that could
be misleading, deceptive or dishonest. `,
  `you have complied with all requirements under the Deferred Sales
Model for add-on insurance products.`,
  `I confirm that the distribution of this product is consistent with
the current Target Market Determination.`,
];

export const whatIsThePurposeOfThisApplicationOption = [
  "Purchase the asset plus finance insurances",
  "Purchase the asset",
  "Refinance loan on the asset",
  "Refinance loan on the asset plus finance insurances",
];
export const whatIsThePurposeOfThisApplicationDetail =
  "Please specify the purpose of the application. For example, purchase the asset, Refinance loan on the asset.";
export const whyDoesThisLoanTermSuitYourRequirementsOption = [
  "Want the maximum amount of time with the minimum repayment",
  "Want to be able to pay off the loan over the selected term as it suits my budget",
  "I want to pay off the loan as quickly as possible (and acknowledge that fees and charges apply for early payout of the loan)",
  "I will be looking to sell the car/ trade in at the end of the loan term",
  "Other",
];
export const whyDoesThisLoanTermSuitYourRequirementsDetail = "Why does this loan term suits your requirement?";

export const whatAreYouLookingForInYourLoanOption = [
  "Competitive Interest Rate",
  "Service",
  "Broker Recommendation",
  "I can make additional repayments at no cost",
  "I can repay the loan early (fees & charges apply)",
  "A balloon repayment",
];
export const whatAreYouLookingForInYourLoanDetail = "Please specify what are you looking for in your loan.";

export const anyForeseeableChangesImpactingLoanRepaymentOption = [
  "End of employment contract/ loss of employment",
  "Leaving employment",
  "Increased debt repayment for a new/ existing loan",
  "Large expenditure",
  "Medical treatment/ illness",
  "Extended unpaid leave (e.g. study or parental leave)",
  "Change to family situation",
  "Retirement",
  "Buying a house",
  "Other",
  "No",
];

export const anyForeseeableChangesImpactingLoanRepaymentDetail =
  "Do you plan or anticipate any foreseeable changes to your personal circumstances that may adversely impact your ability to repay the loan.";

export const whatToDoWithBalloonPaymentAtTheEndOfTheLoanTermOption = [
  "Payout",
  "Refinance the loan",
  "Sell the asset",
  "Trade in for a new vehicle",
];

export const whatToDoWithBalloonPaymentAtTheEndOfTheLoanTermDetail =
  "What do you intend to do at the end of the loan term with the balloon payment?";

export const howApplicantIntendToDoLoanRepaymentAfterChangeDetail =
  "How does the applicant(s) intend to meet their loan repayments following the planned or anticipated change?";

export const increaseInLoanAmountForFinancingCostOfInsuranceDetail =
  "Are you aware that financing the cost of insurances or add-ons will increase the loan amount and interest paid over the loan term. This is relevant for the applications where one or more insurance premiums are being financed.";

export const balloonPaymentAtTheEndOfTheLoanDetail =
  "Do you understand and acknowledge that you will be required to make a large payment (balloon payment) at the end of the loan and will pay interest on the balloon over the term of the loan?";

export const serviceChargesList = [
  {
    service: "Glasses Guide",
    apiCallCount: "10",
    singleApiCallPrice: "0.00",
  },
  {
    service: "Blue flag",
    apiCallCount: "2",
    singleApiCallPrice: "0.70",
  },
  {
    service: "ASIC Extract",
    apiCallCount: "1",
    singleApiCallPrice: "4.64",
  },
  {
    service: "ASIC Extract",
    apiCallCount: "5",
    singleApiCallPrice: "14.73",
  },
  {
    service: "PPSR",
    apiCallCount: "2",
    singleApiCallPrice: "6.00",
  },
  {
    service: "ID verification",
    apiCallCount: "10",
    singleApiCallPrice: "4.20",
  },
  {
    service: "Equifax report",
    apiCallCount: "2",
    singleApiCallPrice: "10.03",
  },
  {
    service: "Equifax report",
    apiCallCount: "1",
    singleApiCallPrice: "11.03",
  },
  {
    service: "Equifax report",
    apiCallCount: "5",
    singleApiCallPrice: "16.81",
  },
  {
    service: "Bank statements",
    apiCallCount: "2",
    singleApiCallPrice: "3.50",
  },
];

export const serviceChargesHeader = ["Service", "Total request", "Service charge", "Total charge", ""];

export const inVoiceHeader = ["Date", "User", "Period", "Amount", "Status", "", ""];

export const cellAlign = "center";
export const fieldRequiredMessage = {
  yearsAt: "yearsAt is required",
  monthsAt: "monthsAt is required",
  assetValue: "Asset value is required",
  netAssetValue: "Net value is required",
  luxuryGst: "Luxury Gst is required",
  assetType: "Asset type is required",
  typeOfSale: "Type of sale is required",
  ageOfAsset: "Age of asset is required",
  glassesMake: "Glasses make is required",
  glassesModel: "Glasses model is required",
  glassesVariant: "Glasses variant is required",
  customerTitle: "Title is required",
  firstName: "First name is required",
  middleNames: "middle name is required",
  lastName: "Last name is required",
  dateOfBirth: "Date of birth is required",
  IDNumber: "ID number is required",
  IDCardNumber: "ID card number is required",
  number: "Phone number is required",
  address: "Email address is required",
  fullAddress: "address is required",
  introducerDeclarationAgree: "Agreement is required",
  make: "make is required",
  model: "model is required",
  variant: "variant is required",
  IDExpiryDate: "Licence expiry date is required",
  monthAtInValid: "MonthsAt  are invalid",
  customerAssetsErrMsg: {
    assetType: "Type is required",
    value: "Amount is required",
    description: "Description is required",
  },
  liabilityErrMsg: {
    liabilitiesType: "Liability is required",
    outstandingAmount: "Amount is required",
    limit: "Limit is required",
    financier: "Financier is required",
    repaymentFrequency: "Frequency is required",
    repaymentAmount: "Amount is required",
    description: "Description is required",
  },
  incomeErrMsg: {
    incomeType: "Income type is required",
    amount: "Amount is required",
    frequency: "Frequency is required",
  },
  expenseErrMsg: {
    expensesType: "Expense type is required",
    amount: "Amount is required",
    frequency: "Frequency is required",
  },
  employeeErrMsg: {
    entityName: "Name is required",
    status: "Status is required",
    occupation: "Occupation is required",
    manager: "Manager name is required",
    phoneNumber: "number is required",
    industry: "Industry is required",
    timeOfEmployment: "Time Of Employment is required",
    fullAddress: "Address is required",
    city: "City is required",
    state: "State is required",
  },
  customErrMsg: {
    fullAddress: "Only australian address is allowed",
    dateOfBirth: "Age should be greater than 18",
    timeOfEmployment: "Time of Employment should be at least of 2 years",
  },
};
export const fieldEntityRequiredMessage = {
  city: "Address is required",
  entityAddress: "Address is required",
  entityName: "Entity is required",
  entityType: "Entity is required",
  abn: "Entity is required",
  industryType: "Industry type is required",
  turnover: "Turnover is required",
};

export const entityType = { soleTrader: "Individual/Sole Trader" };

export const entityStatus = {
  active: "Active",
  registered: "Registered",
};

export const validEntityStatus = [entityStatus.active, entityStatus.registered];

export const creditCardModalInfo = {
  mainTitle: "Looks like you've not added a Credit card.",
  subTitle: "Click on the link below to add your Credit card.",
  btnText: "Add Credit card",
};

export const completedLender = ["Pepper"];

export const STATUS_CODE = { success: 200 };

export const BMA_APPLICATION_STATUS = [
  {
    label: "Workshop",
    value: "Workshop",
  },
  {
    label: "In progress",
    value: "In progress",
  },
  {
    label: "Pending Information",
    value: "Pending Information",
  },
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "Declined",
    value: "Declined",
  },
  {
    label: "Settled",
    value: "Settled",
  },
];

export const AFS_APPLICATION_STATUS = [
  {
    label: "Workshop",
    value: "Workshop",
  },
  {
    label: "Application Completed Pending Info",
    value: "Application Completed Pending Info",
  },
  {
    label: "Lodge Pending Decision",
    value: "Lodge Pending Decision",
  },
  {
    label: "Approved In Progress",
    value: "Approved In Progress",
  },
  {
    label: "Docs Issued",
    value: "Docs Issued",
  },
  {
    label: "Settlement Pending Info",
    value: "Settlement Pending Info",
  },
  {
    label: "Settlement Complete",
    value: "Settlement Complete",
  },
];

export const CONNECTIVE_APPLICATION_STATUS = [
  {
    label: "Workshop",
    value: "Workshop",
  },
  {
    label: "Application Completed Pending Info",
    value: "Application Completed Pending Info",
  },
  {
    label: "Lodge Pending Decision",
    value: "Lodge Pending Decision",
  },
  {
    label: "Approved In Progress",
    value: "Approved In Progress",
  },
  {
    label: "Docs Issued",
    value: "Docs Issued",
  },
  {
    label: "Settlement Pending Info",
    value: "Settlement Pending Info",
  },
  {
    label: "Settlement Complete",
    value: "Settlement Complete",
  },
];

export const REACT_APP_PLATFORM_NAME = process.env.REACT_APP_PLATFORM_NAME;

export const CLIENT_NAMES = {
  CONNECTIVE: "connective",
  BMA: "bma",
  AFS: "afs",
};

export const ID_TYPE = {
  LICENCE: "Licence",
  PASSPORT: "Passport",
};

export const CLIENT_APPLICATION_STATUS = {
  [CLIENT_NAMES.CONNECTIVE]: CONNECTIVE_APPLICATION_STATUS,
  [CLIENT_NAMES.BMA]: BMA_APPLICATION_STATUS,
  [CLIENT_NAMES.AFS]: AFS_APPLICATION_STATUS,
};

export const ROLE_NAMES = {
  USER: "user",
  ADMIN: "admin",
  SUPPORT: "support",
  MASTER: "master",
  OWNER: "owner",
  USER_REFERRER: "user-referrer",
  MASTER_REFERRER: "master-referrer",
  OWNER_REFERRER: "owner-referrer",
};
export const lenderListWith2FA = ["AFS", "Angle Finance", "NOW", "resimac"];

export const YES_NO_OPTIONS = [
  { icon: "House", label: "Yes" },
  { icon: "HomeWork", label: "No" },
];

export const REPAYMENT_METHOD_OPTIONS = [
  { icon: "House", label: "In Advance" },
  { icon: "HomeWork", label: "In Arrears" },
];

export const HEM_CONSTANTS = [
  {
    minIncome: 0,
    maxIncome: 1975.6,
    coupleRate: 2407,
    coupleWithChildren: {
      1: 2805,
      2: 3115,
      3: 3456,
    },
    singleRate: 1269,
    singleWithChildren: {
      1: 1731,
      2: 2207,
      3: 2682,
    },
    additionalChild: 341,
  },
  {
    minIncome: 1975.61,
    maxIncome: 2853.1,
    coupleRate: 2459,
    coupleWithChildren: {
      1: 2858,
      2: 3167,
      3: 3509,
    },
    singleRate: 1322,
    singleWithChildren: {
      1: 1784,
      2: 2259,
      3: 2735,
    },
    additionalChild: 341,
  },
  {
    minIncome: 2853.11,
    maxIncome: 3606.86,
    coupleRate: 2533,
    coupleWithChildren: {
      1: 2931,
      2: 3241,
      3: 3582,
    },
    singleRate: 1395,
    singleWithChildren: {
      1: 1857,
      2: 2333,
      3: 2808,
    },
    additionalChild: 341,
  },
  {
    minIncome: 3606.87,
    maxIncome: 4338.11,
    coupleRate: 2621,
    coupleWithChildren: {
      1: 3019,
      2: 3330,
      3: 3671,
    },
    singleRate: 1483,
    singleWithChildren: {
      1: 1945,
      2: 2421,
      3: 2896,
    },
    additionalChild: 341,
  },
  {
    minIncome: 4338.12,
    maxIncome: 5013.11,
    coupleRate: 2786,
    coupleWithChildren: {
      1: 3185,
      2: 3496,
      3: 3839,
    },
    singleRate: 1647,
    singleWithChildren: {
      1: 2110,
      2: 2586,
      3: 3061,
    },
    additionalChild: 343,
  },
  {
    minIncome: 5013.12,
    maxIncome: 6419.36,
    coupleRate: 3032,
    coupleWithChildren: {
      1: 3433,
      2: 3744,
      3: 4090,
    },
    singleRate: 1892,
    singleWithChildren: {
      1: 2357,
      2: 2832,
      3: 3307,
    },
    additionalChild: 345,
  },
  {
    minIncome: 6419.37,
    maxIncome: 7806.86,
    coupleRate: 3384,
    coupleWithChildren: {
      1: 3787,
      2: 4099,
      3: 4448,
    },
    singleRate: 2242,
    singleWithChildren: {
      1: 2709,
      2: 3184,
      3: 3659,
    },
    additionalChild: 349,
  },
  {
    minIncome: 7806.87,
    maxIncome: 9119.36,
    coupleRate: 3688,
    coupleWithChildren: {
      1: 4092,
      2: 4405,
      3: 4757,
    },
    singleRate: 2543,
    singleWithChildren: {
      1: 3012,
      2: 3487,
      3: 3961,
    },
    additionalChild: 352,
  },
  {
    minIncome: 9119.37,
    maxIncome: 10431.86,
    coupleRate: 4071,
    coupleWithChildren: {
      1: 4477,
      2: 4791,
      3: 5147,
    },
    singleRate: 2924,
    singleWithChildren: {
      1: 3395,
      2: 3870,
      3: 4344,
    },
    additionalChild: 356,
  },
  {
    minIncome: 10431.87,
    maxIncome: 11611.04,
    coupleRate: 4265,
    coupleWithChildren: {
      1: 4673,
      2: 4987,
      3: 5345,
    },
    singleRate: 3117,
    singleWithChildren: {
      1: 3590,
      2: 4064,
      3: 4538,
    },
    additionalChild: 358,
  },
  {
    minIncome: 11611.05,
    maxIncome: 13948.54,
    coupleRate: 4457,
    coupleWithChildren: {
      1: 4866,
      2: 5181,
      3: 5540,
    },
    singleRate: 3308,
    singleWithChildren: {
      1: 3782,
      2: 4256,
      3: 4730,
    },
    additionalChild: 360,
  },
  {
    minIncome: 13948.55,
    maxIncome: 16790.2,
    coupleRate: 4984,
    coupleWithChildren: {
      1: 5396,
      2: 5712,
      3: 6076,
    },
    singleRate: 3832,
    singleWithChildren: {
      1: 4309,
      2: 4783,
      3: 5256,
    },
    additionalChild: 365,
  },
  {
    minIncome: 16790.21,
    maxIncome: 19677.7,
    coupleRate: 5476,
    coupleWithChildren: {
      1: 5891,
      2: 6208,
      3: 6578,
    },
    singleRate: 4321,
    singleWithChildren: {
      1: 4802,
      2: 5274,
      3: 5747,
    },
    additionalChild: 370,
  },
  {
    minIncome: 19677.71,
    maxIncome: 31136.04,
    coupleRate: 5760,
    coupleWithChildren: {
      1: 6176,
      2: 6494,
      3: 6866,
    },
    singleRate: 4603,
    singleWithChildren: {
      1: 5085,
      2: 5558,
      3: 6031,
    },
    additionalChild: 373,
  },
  {
    minIncome: 31136.05,
    maxIncome: 45857777.75,
    coupleRate: 6018,
    coupleWithChildren: {
      1: 6435,
      2: 6754,
      3: 7128,
    },
    singleRate: 4859,
    singleWithChildren: {
      1: 5343,
      2: 5815,
      3: 6288,
    },
    additionalChild: 375,
  },
];
export const tabList = [
  "Applications",
  "Services",
  "organisations",
  // "User Setting",
  "Users",
  "Lenders",
];
export const userStatusList = ["Pending", "Approved", "Live", "Declined"];
